var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true
      },
      on: { close: _vm.drawerClose }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.drawerTitle))
      ]),
      _c(
        "a-card",
        { attrs: { title: "属性列表" } },
        [
          _c(
            "jtl-button",
            {
              staticClass: "table-header-button",
              attrs: {
                slot: "extra",
                type: "primary",
                disabled: _vm.saveBtnDisabled
              },
              on: { click: _vm.saveClick },
              slot: "extra"
            },
            [_vm._v("保存")]
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              pagination: false,
              scroll: _vm.tableScroll,
              "row-key": "uuid",
              size: _vm.TableSize
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u([
              {
                key: "dataValue",
                fn: function(text, record) {
                  return [
                    record
                      ? _c("jtl-entity-attribute", {
                          attrs: {
                            record: record,
                            "facility-type-id": _vm.facilityTypeId
                          },
                          on: { change: _vm.getSaveBtnStatus }
                        })
                      : _vm._e()
                  ]
                }
              },
              {
                key: "action",
                fn: function(text, record) {
                  return [
                    [
                      _vm.FacilityAttributeDataType.DEVICE,
                      _vm.FacilityAttributeDataType.ATTRIBUTE,
                      _vm.FacilityAttributeDataType.SERVER_ATTRIBUTE
                    ].indexOf(record.type) > -1
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.detailClick(record)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("device-status-dialog", { ref: "deviceStatusDialog" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }