
import { FacilityAttributeEntityModel, FacilityAttributeQueryModel } from '@/entity-model/facility-attribute-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/facilityV2`;

class FacilityAttributeV2Service implements ICRUDQ<FacilityAttributeEntityModel, FacilityAttributeQueryModel> {
    async create(model: FacilityAttributeEntityModel):Promise<FacilityAttributeEntityModel> {
        throw new Error('Not implement');
    }

    async retrieve(modelId: string):Promise<FacilityAttributeEntityModel> {
        throw new Error('Not implement');
    }

    async update(model: FacilityAttributeEntityModel):Promise<FacilityAttributeEntityModel> {
        throw new Error('Not implement');
    }

    async delete(model: FacilityAttributeEntityModel):Promise<FacilityAttributeEntityModel> {
        throw new Error('Not implement');
    }

    async query(query?: FacilityAttributeQueryModel):Promise<any> {
        // const params: any = query?.toService();
        const url = `${URL_PATH}/getAttrs/${(query as FacilityAttributeQueryModel).entityId}`;
        const res = await get(url);
        return _.map(res, item => item = new FacilityAttributeEntityModel(false, (query as FacilityAttributeQueryModel).entityId).toModel(item));
    }

    async saveFacilityAttribute(facilityList: Array<FacilityAttributeEntityModel>):Promise<any> {
        const url = `${URL_PATH}/saveAttr`;
        const params = _.map(facilityList, item => item.toValueService());
        const res = await post(url, params);
        return res;
    }
}

export default new FacilityAttributeV2Service();
