












































































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import GroupComponent from '@/mixins/group-component';
import TableTreeComponent from '@/mixins/table-tree-component';
import { FacilityV2EntityModel, FacilityV2QueryModel } from '@/entity-model/facility-v2-entity';
import FacilityV2Service from '@/service/facility-v2';
import FacilityAttributeDrawer from '@/pages/dashboard/facility-v2/facility-attribute-drawer.vue';
import FacilityLinkChildDialog from '../facility/facility-link-child-dialog.vue';
import FacilityBatchAddDialog from './facility-batch-add-dialog.vue';
import FacilityBatchPositionDialog from './facility-batch-position-dialog.vue';
import FacilityBatchGroupDialog from './facility-batch-group-dialog.vue';
import { ViewModeType, TagEntityType } from '@/model/enum';
import FaciltiyBatchLabelDialog from './facility-batch-add-label-dialog.vue';
import FacilityBatchDeleteLabelDialog from './facility-batch-delete-label-dialog.vue';
import LabelService from '@/service/label';

@Component({
    components: {
        'facility-attribute-drawer': FacilityAttributeDrawer,
        'facility-link-child-dialog': FacilityLinkChildDialog,
        'facility-batch-add-dialog': FacilityBatchAddDialog,
        'facility-batch-position-dialog': FacilityBatchPositionDialog,
        'facility-batch-group-dialog': FacilityBatchGroupDialog,
        'facility-batch-label-dialog': FaciltiyBatchLabelDialog,
        'facility-batch-delete-label-dialog': FacilityBatchDeleteLabelDialog
    }
})
export default class FacilityV2ListComponent extends Mixins(TableDialogFormComponent, GroupComponent, TableTreeComponent) {
    FacilityV2EntityModel = FacilityV2EntityModel;
    TagEntityType = TagEntityType;
    created() {
        this.treeEntityService = FacilityV2Service;
        this.initTable({
            service: FacilityV2Service,
            queryModel: new FacilityV2QueryModel(),
            tableColumns: FacilityV2EntityModel.getTableColumns()
        });
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as FacilityV2QueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/facility-v2?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/facility-v2');
        }
    }

    attributeClick(model: FacilityV2EntityModel) {
        (this.$refs.facilityAttributeDrawer as FacilityAttributeDrawer).drawAttributeOpen(model.id, model.name, model.typeId);
    }

    deleteBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设施');
            return;
        }
        const confirm = this.$confirm({
            title: '批量删除',
            content: `确认删除${this.selectedRowKeys?.length}个设施`,
            onOk: () => {
                this.startFullScreenLoading('正在删除');
                return (this.treeEntityService as any).batchDelete(this.getSelectedRowIds()).then((ret) => {
                    this.showMessageSuccess('批量删除成功');
                    this.getList();
                    return true;
                }).catch((err) => {
                    throw err;
                }).finally(() => {
                    this.stopFullScreenLoading();
                });
            },
            onCancel: () => {
                confirm.destroy();
            }
        });
    }

    deleteBatchLabelClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设施');
            return;
        }
        // 获取所有标签
        const params = {
            entityIds: this.getSelectedRowIds(),
            relType: TagEntityType.FACILITY_V2
        };

        LabelService.selectTagsByEntityIdsAndEntityType(params).then(res => {
            (this.$refs.formBatchDeleteLabelDialog as FacilityBatchDeleteLabelDialog).dialogOpen({ tagIds: res, selectedRowIds: this.getSelectedRowIds() }, null, ViewModeType.NEW);
        });
    }

    positionBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设施');
            return;
        }
        (this.$refs.formBatchPositionDialog as FacilityBatchPositionDialog).dialogOpen(this.getSelectedRowIds(), null, ViewModeType.NEW);
    }
    addBatchClick(model: FacilityV2EntityModel) {
        (this.$refs.formBatchAddDialog as FacilityBatchAddDialog).dialogOpen(this.GroupId, _.get(model, 'id'));
    }
    groupBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设施');
            return;
        }
        (this.$refs.formBatchGroupDialog as FacilityBatchGroupDialog).dialogOpen(this.getSelectedRowIds(), null, ViewModeType.NEW);
    }
    addBatchLabelClick(tagEntityType:TagEntityType) {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设施');
            return;
        }
        (this.$refs.formBatchLabelDialog as FaciltiyBatchLabelDialog).dialogOpen({ selectedRowKeys: this.getSelectedRowIds(), tagEntityType }, null, ViewModeType.NEW);
    }
}

