























































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { FacilityType, GroupEntityType } from '@/model/enum';
import DeviceTypeService from '@/service/device-type';
import { FacilityService } from '@/service/facility';
import { FacilityEntityModel, FacilityQueryModel } from '@/entity-model/facility-entity';

@Component
export default class FacilityLinkChildDialog extends BaseComponent {
    facilityType: FacilityType;
    allTypeList: Array<any> = null;
    queryLoading: boolean = false;
    dialogVisible: boolean = false;
    GroupEntityType = GroupEntityType;
    parentId: string;
    rightDataList: Array<FacilityEntityModel> = null;
    facilityService: FacilityService;
    listData: Array<FacilityEntityModel> = null;
    searchText: string = null;

    created() {
        this.facilityService = new FacilityService(this.facilityType);
    }

    get FilterListData() {
        const trimText = _.toUpper(_.trim(this.searchText));
        if (trimText) {
            return _.filter(this.listData, item => _.toUpper(item.name).indexOf(trimText) > -1);
        }
        return this.listData;
    }

    dialogOpen(parentId: string, facilityType: FacilityType) {
        this.facilityType = facilityType;
        this.facilityService.type = facilityType;
        this.parentId = parentId;
        this.dialogVisible = true;

        this.queryLoading = true;
        // Promise.all([this.facilityService.getFacilityList(parentId), this.facilityService.getLinkedFacilityList(parentId)]).then(res => {
        this.facilityService.getFacilityList(parentId).then(res => {
            this.listData = _.filter(res, item => item.id !== parentId);
            // this.rightDataList = _.get(res, '[1]');
            this.rightDataList = [];
        }).catch(err => {
            this.showContactAdmin();
            this.dialogClose();
        }).finally(() => {
            this.queryLoading = false;
        });
    }

    dialogOK() {
        return this.facilityService.links(this.parentId, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK', this.parentId);
        }).catch(err => {
            throw err;
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = null;
        this.parentId = null;
        this.dialogVisible = false;
    }

    checkOnChange(model: FacilityEntityModel) {
        if (model.check) {
            this.rightDataList.push(model);
        } else {
            const index = _.findIndex(this.rightDataList, item => item.id === model.id);
            if (index > -1) {
                this.rightDataList.splice(index, 1);
            }
        }
    }

    removeItemClick(model, index) {
        model.check = false;
        const listDataItem = _.find(this.listData, item => item.id === model.id);
        if (listDataItem) {
            listDataItem.check = false;
        }
        this.rightDataList.splice(index, 1);
    }
    removeAllClick() {
        this.rightDataList = [];
        _.forEach(this.listData, item => {
            item.check = false;
        });
    }
}
