

























































































import { Component, Ref } from 'vue-property-decorator';
import TableComponent from '@/mixins/table-component';
import { FacilityAttributeDataType, FormControlSelectDeviceAttributeType, GroupEntityType } from '@/model/enum';
import { DeviceEntityModel, DeviceQueryModel } from '@/entity-model/device-entity';
import FacilityV2Service from '@/service/facility-v2';
import FacilityTypeAttributeService from '@/service/facility-type-attribute';
import { FacilityEntityModel, FacilityQueryModel } from '@/entity-model/facility-entity';
import { FormControlOptionModel, FormControlType } from '@/model/form-control';
import CommonService from '@/service/common';
import DeviceService from '@/service/device';
import FormComponent from '@/mixins/form-component';
import { FacilityAttributeEntityModel, FacilityAttributeQueryModel } from '@/entity-model/facility-attribute-entity';
import { facilityAttributeDataTypeFilter } from '@/filter';
import { FacilityV2QueryModel } from '@/entity-model/facility-v2-entity';
import FacilityGroupService from '@/service/facility-group';
import SpaceV2Service from '@/service/space-v2';

@Component
export default class FacilityBatchAddDialog extends TableComponent<DeviceEntityModel, DeviceQueryModel> {
    allTypeList: Array<any> = null;
    queryLoading: boolean = false;
    dialogVisible: boolean = false;
    GroupEntityType = GroupEntityType;
    parentId: string;
    rightDataList: Array<any> = [];
    attributeListData: Array<FacilityAttributeEntityModel> = null;
    // deviceTypeList: Array<any> = null;
    facilityTypeId: string = null;
    linkedFacility: Array<FacilityEntityModel> = null;
    FormControlSelectDeviceAttributeType = FormControlSelectDeviceAttributeType;

    @Ref('facilityForm')
    jtlForm: FormComponent<any>;

    get ListData() {
        if (this.linkedFacility && this.linkedFacility.length > 0) {
            return _.filter(this.listData, item => _.findIndex(this.linkedFacility, li => li.name === item.displayName) === -1);
        }
        return this.listData;
    }

    get DeviceTypeList() {
        const deviceTypeList = [];
        _.forEach(this.attributeListData, item => {
            if ([FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(item.type) > -1) {
                _.forEach(item.linkedDeviceAttributeList, attrItem => {
                    deviceTypeList.push({ name: attrItem.name, value: attrItem.id });
                });
            } else if (item.type === FacilityAttributeDataType.DEVICE) {
                _.forEach(item.attrGroupsList, deviceItem => {
                    deviceTypeList.push({ name: deviceItem.deviceTypeName, value: deviceItem.deviceTypeId });
                });
            }
        });
        return _.filter(_.uniqBy(deviceTypeList, 'value'), item => item.value);
    }

    created() {
        const deviceQueryModel = new DeviceQueryModel();
        deviceQueryModel.forceRoot = false;
        this.isLoadMore = true;
        this.pageSize = 0;
        this.initTable({
            listFunc: DeviceService.listFacDevs,
            queryModel: deviceQueryModel,
            tableColumns: [
                {
                    title: '属性名称',
                    dataIndex: 'name',
                    width: 200
                },
                {
                    title: '属性类型',
                    dataIndex: 'type',
                    width: 150,
                    customRender: (text) => {
                        return facilityAttributeDataTypeFilter(text);
                    }
                },
                {
                    title: '映射产品属性',
                    dataIndex: 'dataValue',
                    scopedSlots: { customRender: 'dataValue' }
                }
            ] });
    }

    formOnChange(formControlModel: FormControlOptionModel) {
        if (formControlModel.key === 'facilityTypeId') {
            this.facilityTypeId = _.get(this.$refs, 'facilityForm.formModel.facilityTypeId') as any;
            const queryModel: FacilityAttributeQueryModel = new FacilityAttributeQueryModel(this.facilityTypeId, true);
            FacilityTypeAttributeService.query(queryModel).then(res => {
                this.attributeListData = res;
                this.queryModel.typeId = _.get(this.DeviceTypeList, '[0].value');
            });
        }
    }

    dialogOpen(groupId: string, parentId: string) {
        this.parentId = parentId;
        this.dialogVisible = true;
        // CommonService.getDeviceTypeOptions().then(res => {
        //     this.deviceTypeList = res;
        // });
        if (this.parentId) {
            const queryModel = new FacilityV2QueryModel();
            queryModel.forceRoot = false;
            queryModel.parentId = this.parentId;
            FacilityV2Service.query(queryModel, 1, 1000).then(res => {
                this.linkedFacility = _.get(res, 'items');
            });
        }
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'groupIds',
                        label: '分组',
                        value: groupId ? [groupId] : null,
                        type: FormControlType.TREE_SELECT,
                        optionsPromise: FacilityGroupService.getGroupTree,
                        optionsPromiseParam: GroupEntityType.FACILITY,
                        mode: 'multiple',
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' },
                        required: true
                    },
                    {
                        key: 'positionId',
                        label: '安装位置',
                        type: FormControlType.TREE_SELECT,
                        optionsPromise: SpaceV2Service.getLocationTree,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' },
                        required: false
                    },
                    {
                        key: 'facilityTypeId',
                        label: '业务类型',
                        type: FormControlType.SELECT,
                        optionsPromise: CommonService.getAllFacilityType,
                        required: true,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' }
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    getAttrListByRightData(rightDataItem) {
        const attrList = [];
        _.forEach(this.attributeListData, attrItem => {
            const attrValue = attrItem.toService();
            if ([FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(attrItem.type) > -1) {
                _.forEach(attrValue.relations, rItem => {
                    if (rightDataItem.deviceTypeId === rItem.entityId) {
                        attrList.push({
                            name: attrItem.name,
                            linkId: rightDataItem.id,
                            attributeKey: rItem.attrKey,
                            type: attrValue.type,
                            identifier: attrItem.identifier
                        });
                    }
                });
            } else if (attrItem.type === FacilityAttributeDataType.DEVICE) {
                // _.forEach(attrValue.attrGroupsList, rItem => {
                //     attrList.push({
                //         name: attrItem.name,
                //         linkId: rightDataItem.id,
                //         type: attrValue.type,
                //         identifier: attrItem.identifier
                //     });
                // });
                attrList.push({
                    name: attrItem.name,
                    linkId: rightDataItem.id,
                    type: attrItem.type,
                    identifier: attrItem.identifier
                });
            } else {
                attrList.push(attrValue);
            }
        });
        return attrList;
    }

    dialogOK() {
        // this.showDeveloping();
        if (!this.jtlForm) {
            return;
        }
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                // if (this.attributeListData) {
                //     let hasAttributeValueError = _.findIndex(this.attributeListData, item => [FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(item.type) > -1 && !item.dataValue) > -1;
                //     if (hasAttributeValueError) {
                //         this.showMessageWarning('请选择映射产品属性');
                //         reject();
                //         return;
                //     }
                // }
                if (!this.rightDataList || this.rightDataList.length === 0) {
                    this.showMessageWarning('请选择要导入的设备');
                    reject();
                    return;
                }
                const params = _.map(this.rightDataList, item => {
                    return {
                        attrList: this.getAttrListByRightData(item),
                        entity: {
                            parentId: this.parentId,
                            name: item.name,
                            code: item.code,
                            locationId: this.jtlForm.formModel['positionId'],
                            typeId: this.jtlForm.formModel['facilityTypeId']
                        }
                        // groupIds: this.jtlForm.formModel['groupIds']
                    };
                });
                // const params = {
                //     facilityNames: _.map(this.rightDataList, item => item.name),
                //     locationId: this.jtlForm.formModel['positionId'],
                //     groupIds: this.jtlForm.formModel['groupIds'],
                //     typeId: this.jtlForm.formModel['facilityTypeId']
                // };
                FacilityV2Service.batchCreateFacility({ facilities: params, groupIds: this.jtlForm.formModel['groupIds'] }).then((ret) => {
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(true);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = [];
        this.parentId = null;
        this.attributeListData = null;
        this.isAutoQuery = false;
        this.queryModel.typeId = undefined;
        this.queryModel.spaceIds = undefined;
        this.dialogVisible = false;
        this.$nextTick(() => {
            this.isAutoQuery = true;
        });
    }

    checkOnChange(model: any) {
        if (model.check) {
            this.rightDataList.push({ id: model.id, code: model.code, name: model.displayName, deviceTypeId: this.queryModel.typeId });
        } else {
            const index = _.findIndex(this.rightDataList, item => item.id === model.id);
            if (index > -1) {
                this.rightDataList.splice(index, 1);
            }
        }
    }

    removeItemClick(model, index) {
        model.check = false;
        const listDataItem = _.find(this.ListData, item => item.id === model.id);
        if (listDataItem) {
            listDataItem.check = false;
        }
        this.rightDataList.splice(index, 1);
    }
    removeAllClick() {
        this.rightDataList = [];
        _.forEach(this.ListData, item => {
            item.check = false;
        });
    }

    get AllCheck() {
        if (!this.ListData || this.ListData.length === 0) {
            return false;
        }
        return _.filter(this.ListData, item => item.check).length === this.ListData.length;
    }
    set AllCheck(val: boolean) {
        _.forEach(this.ListData, item => {
            item.check = val;
            if (val) {
                const index = _.findIndex(this.rightDataList, rightItem => rightItem.id === item.id);
                if (index === -1) {
                    this.rightDataList.push({ id: item.id, code: item.code, name: item.displayName, deviceTypeId: this.queryModel.typeId });
                }
            }
        });
        if (!val) {
            this.rightDataList = [];
        }
    }
}
