var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: "批量添加设施", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "facility-batch-add-component form-edit-component-large transform-dialog"
        },
        [
          _c("jtl-form", {
            ref: "facilityForm",
            on: { change: _vm.formOnChange }
          }),
          _c("a-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.facilityTypeId,
                expression: "facilityTypeId"
              }
            ],
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.attributeListData,
              pagination: false,
              "row-key": "id",
              size: "small"
            },
            scopedSlots: _vm._u([
              {
                key: "dataValue",
                fn: function(text, record) {
                  return [
                    record
                      ? _c("jtl-entity-attribute", {
                          attrs: {
                            record: record,
                            "facility-type-id": _vm.facilityTypeId,
                            "select-type":
                              _vm.FormControlSelectDeviceAttributeType.DEFAULT,
                            "has-edit": false
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("br"),
          _c(
            "a-spin",
            { attrs: { spinning: _vm.queryLoading } },
            [
              _c(
                "a-row",
                {
                  staticClass: "transform-header",
                  attrs: { type: "flex", align: "bottom" }
                },
                [
                  _c("a-col", { attrs: { span: 11 } }, [
                    _vm._v(" 选择还未导入的设备 ")
                  ]),
                  _c("a-col", { attrs: { span: 11, offset: 2 } }, [
                    _vm._v(" 已选"),
                    _c(
                      "a",
                      {
                        staticClass: "transform-remove-all jtl-link pull-right",
                        on: { click: _vm.removeAllClick }
                      },
                      [_vm._v("全部删除")]
                    )
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    {
                      staticClass: "transform-column left-transform-column",
                      attrs: { span: 11 }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { padding: "8px 16px" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                placeholder: "请选择产品",
                                "show-search": "",
                                "option-filter-prop": "title",
                                disabled:
                                  _vm.rightDataList &&
                                  _vm.rightDataList.length > 0
                              },
                              model: {
                                value: _vm.queryModel.typeId,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryModel, "typeId", $$v)
                                },
                                expression: "queryModel.typeId"
                              }
                            },
                            _vm._l(_vm.DeviceTypeList, function(item, index) {
                              return _c(
                                "a-select-option",
                                {
                                  key: index,
                                  attrs: { value: item.value, title: item.name }
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("jtl-select-space-component", {
                            attrs: {
                              "has-child-id": true,
                              disabled: !_vm.queryModel.typeId
                            },
                            model: {
                              value: _vm.queryModel.spaceIds,
                              callback: function($$v) {
                                _vm.$set(_vm.queryModel, "spaceIds", $$v)
                              },
                              expression: "queryModel.spaceIds"
                            }
                          }),
                          _vm._v(" "),
                          _c("a-input-search", {
                            staticClass: "page-table-search__input",
                            attrs: {
                              placeholder: "请输入设备名称",
                              "allow-clear": ""
                            },
                            model: {
                              value: _vm.queryModel.name,
                              callback: function($$v) {
                                _vm.$set(_vm.queryModel, "name", $$v)
                              },
                              expression: "queryModel.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "ul",
                        { staticClass: "ul-list" },
                        [
                          _c(
                            "li",
                            [
                              _c(
                                "a-checkbox",
                                {
                                  model: {
                                    value: _vm.AllCheck,
                                    callback: function($$v) {
                                      _vm.AllCheck = $$v
                                    },
                                    expression: "AllCheck"
                                  }
                                },
                                [_vm._v("全部")]
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.ListData, function(item, index) {
                            return _c(
                              "li",
                              { key: index },
                              [
                                _c(
                                  "jtl-tooltip-component",
                                  {
                                    staticClass: "transform-name text-ellipsis",
                                    attrs: {
                                      title: item.displayName,
                                      placement: "left"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-checkbox",
                                      {
                                        on: {
                                          change: function($event) {
                                            return _vm.checkOnChange(item)
                                          }
                                        },
                                        model: {
                                          value: item.check,
                                          callback: function($$v) {
                                            _vm.$set(item, "check", $$v)
                                          },
                                          expression: "item.check"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.displayName) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _vm.ListData && _vm.ListData.length < _vm.total
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "jtl-link",
                                    on: { click: _vm.loadMoreClick }
                                  },
                                  [_vm._v("加载更多")]
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "transform-column-middle",
                      attrs: { span: 2 }
                    },
                    [
                      _c("jtl-icon", {
                        staticClass: "center",
                        attrs: { type: "transform" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "transform-column", attrs: { span: 11 } },
                    [
                      _c(
                        "ul",
                        { staticClass: "ul-list" },
                        _vm._l(_vm.rightDataList, function(item, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c(
                                "jtl-tooltip-component",
                                {
                                  staticClass:
                                    "transform-name transform-name-right text-ellipsis",
                                  attrs: {
                                    title: item.name,
                                    placement: "right"
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c("a-icon", {
                                staticClass: "right-close-icon",
                                attrs: { type: "close-circle" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeItemClick(item, index)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }