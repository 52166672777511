

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { FormControlType } from '@/model/form-control';
import { ICRUDQ } from '@/model/interface';
import FacilityV2Service from '@/service/facility-v2';
import SpaceV2Service from '@/service/space-v2';
import { ViewModeType } from '@/model/enum';

@Component
export default class FacilityBatchPositionDialog extends FormDialogComponent<any> {
    private ids: Array<string>;
    dialogOpen(selectIds: any, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.ids = selectIds;
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.dialogTitle = '批量修改安装位置';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItem = {
                    key: 'positionId',
                    label: '安装位置',
                    type: FormControlType.TREE_SELECT,
                    optionsPromise: SpaceV2Service.getLocationTree,
                    required: true
                };
                this.jtlForm.initFormByControls([controlItem]);
            }
        });
    }

    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            FacilityV2Service.batchPosition(this.ids, this.jtlForm.formModel.positionId).then(res => {
                this.showMessageSuccess('批量修改设施安装位置成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }).catch(err => {
            throw err;
        });
    }
}
